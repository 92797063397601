import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SitePageType } from '../../../../../common-app/sites/enums/sitePageType';
import { SitePageTypesInfo } from '../../../../../common-frontend/features/sites/sitePageTypesInfo';
import { useForceRefresh } from '../../../../../common-frontend/hooks/refresh/forceRefresh';
import { LocalStorage } from '../../../../../common-frontend/library/localStorage/localStorage';
import { GuidUtil } from '../../../../../common/util/guid/guid';
import { ServiceApiSites } from '../../../../../services/api/serviceApiSites';
import { SiteEditorHeaderComponent } from '../siteEditorHeader/siteEditorHeader';
import { SiteEditorMainAreaComponent } from '../siteEditorMainArea/siteEditorMainArea';
import { SiteEditorSidePanelComponent } from '../siteEditorSidePanel/siteEditorSidePanel';

import './siteEditor.css';

interface SiteEditorProps {
  siteId: string;
  site: string;
}

export function SiteEditorComponent({ siteId, site }: SiteEditorProps): JSX.Element {

  const { t } = useTranslation();

  const [selectedItemId, setSelectedItem] = useState(siteId);
  const [siteEditorSidePanelDataVersion, updateSiteEditorSidePanelDataVersion] = useForceRefresh();

  useEffect(() => {
    // Try to load the last selected item from the local storage
    //const lastSelectedItemId = localStorage.getItem(`${siteId}-siteEditorSelectedItemId`);
    const lastSelectedItemId = LocalStorage.loadItem<string>(`last-edited-page`, siteId);

    if (lastSelectedItemId != undefined) {
      setSelectedItem(lastSelectedItemId);
    }
    else {
      // If no item was found, select the first page
      ServiceApiSites.getSitePagesEditorList(siteId).then((pages) => {
        if (pages.length > 0) {
          var firstPage = pages[0];
          setSelectedItem(firstPage.id);
        }
      });
    }
  }, []);

  useEffect(() => {

    // If the selected item is the site itself, we don't need to save it
    if (selectedItemId === siteId) {
      return;
    }

    // Save the selected item to the local storage
    LocalStorage.saveItem(`last-edited-page`, siteId, selectedItemId);
  }, [selectedItemId]);

  const handleAddNewPage = async (newPageType: SitePageType) => {

    // Determine the page we're usig as a positional reference for the new page
    const anchorPageId = selectedItemId !== siteId ? selectedItemId : undefined;

    const pageName = t(SitePageTypesInfo.GetPageTypeName(newPageType));

    const newPageId = GuidUtil.GenerateNewGuid();
    const result = await ServiceApiSites.addSitePage(siteId, newPageId, newPageType, pageName, anchorPageId);

    if (result) {
      setSelectedItem(newPageId);
      updateSiteEditorSidePanelDataVersion();
    }
  }

  const callbackPagesChanged = useCallback((): void => {
    updateSiteEditorSidePanelDataVersion();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteId, siteEditorSidePanelDataVersion]);

  return (
    <div className="site-editor-component">

      {/* Header */}
      <SiteEditorHeaderComponent />

      {/* Work Area */}
      <div className='work-area'>

        {/* Side Panel */}
        <SiteEditorSidePanelComponent
          dataVersion={siteEditorSidePanelDataVersion}
          site={site}
          siteId={siteId}
          selectedItemId={selectedItemId}
          setSelectedItem={setSelectedItem}
          onAddNewPage={(newPageType) => handleAddNewPage(newPageType)}
          callbackPagesChanged={callbackPagesChanged}
        />

        {/* Main Area */}
        <div className='main-area'>
          <SiteEditorMainAreaComponent
            siteId={siteId}
            selectedItemId={selectedItemId}
            callbackPagesChanged={callbackPagesChanged}
          />
        </div>
      </div>
    </div>
  );
}
