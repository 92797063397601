import { Container } from "react-bootstrap";
import { PageTitle } from "../common-frontend/components/pageTitle/pageTitle";
import { Link } from "react-router-dom";
import { VerticalListComponent } from "../common-frontend/components/verticalList/verticalList";
import { TabTitleComponent } from "../common-frontend/components/tabTitle/tabTitleComponent";
import { useTranslationCommonFrontEnd } from "../common-frontend/translations/translation";

export function PageNotFound(): JSX.Element {

  const t = useTranslationCommonFrontEnd();

  return (
    <div>
      <Container>
        <TabTitleComponent title={t('pixelVaidoso')} />
        <PageTitle title={t('pageNotFound')} />

        <VerticalListComponent>
          <h4>{t('pageNotFoundMessage1')}</h4>
          <Link to={`/`}><h5>{t('home')}</h5></Link>
        </VerticalListComponent>
      </Container>
    </div>
  );
}