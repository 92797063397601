import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { PageTitle } from "../../common-frontend/components/pageTitle/pageTitle";
import { VerticalWhiteSpaceComponent } from "../../common-frontend/components/verticalWhiteSpace/verticalWhiteSpace";
import { CreateSiteActionComponent } from "../../components/home/createSiteAction/createSiteAction";
import { StyleManagerPixelVaidosoComponent } from "../../components/sites/rendering/styleManager/styleManagerPixelVaidoso";
import { TabTitleComponent } from "../../common-frontend/components/tabTitle/tabTitleComponent";

import "./page1Click.css";

export function Page1Click(): JSX.Element {

  const { t } = useTranslation();

  return (
    <Container>
      <TabTitleComponent title={t('pixelVaidoso')} />

      <StyleManagerPixelVaidosoComponent />

      <div className="page-1-click-component">
        <PageTitle title="Sites 1-click" />

        <div className="banner">
          {t('createWebsitesInMinute')}
        </div>
        <VerticalWhiteSpaceComponent amount={1} />

        <h4>
          {t('howItWorks')}
        </h4>
        <div className="text-wall">
          <p>
            {t('oneClickSystem')}
          </p>
          <p>
            {t('withOurEditor')}
          </p>
          <p>
            {t('consultOurPage')} <Link to="/services">{t('services')}</Link>.
          </p>
          <p>
            {t('tryWithoutRegistering')}
          </p>
        </div>

        <VerticalWhiteSpaceComponent amount={1} />

        <CreateSiteActionComponent />

        <VerticalWhiteSpaceComponent amount={1} />

        <h5>{t('examples')}</h5>
        <ul>
          <li>
            {t('businessName1')}
          </li>
          <li>
            {t('businessName2')}
          </li>
          <li>
            {t('businessName3')}
          </li>
          <li>
            {t('businessName4')}
          </li>
        </ul>

        <VerticalWhiteSpaceComponent amount={3} />

      </div>
    </Container>
  );
}