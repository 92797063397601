import { useEffect } from "react";
import { TabTitle } from "../../library/tabTitle/tabTitle";

interface TabTitleProps {
  title: string;
}

export function TabTitleComponent({ title }: TabTitleProps): JSX.Element {
  useEffect(() => {
    TabTitle.updateTitle(title);
  }, [title]);

  return <></>
}