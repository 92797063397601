import { useEffect, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { SiteOwnership } from '../../../common-app/sites/models/siteOwnership/siteOwnership';
import { PageTitle } from '../../../common-frontend/components/pageTitle/pageTitle';
import { VerticalWhiteSpaceComponent } from '../../../common-frontend/components/verticalWhiteSpace/verticalWhiteSpace';
import { useLocalSessionId, useLocalSessionUserId } from '../../../library/hooks/session/useUserSession';
import { ServiceApiSites } from '../../../services/api/serviceApiSites';
import { ServiceFeatureAnalytics } from '../../../services/features/analytics/serviceFeatureAnalytics';
import { SiteInformationComponent } from './siteInformation/siteInformation';
import { StyleManagerPixelVaidosoComponent } from '../../../components/sites/rendering/styleManager/styleManagerPixelVaidoso';
import { TabTitleComponent } from '../../../common-frontend/components/tabTitle/tabTitleComponent';

import './userSites.css';

export function PageUserSites(): JSX.Element {

  const { t } = useTranslation();

  const userId = useLocalSessionUserId();
  const sessionId = useLocalSessionId();

  const [sitesOwnedByUser, setSitesOwnedByUser] = useState<SiteOwnership[]>([]);
  const [unclaimedSites, setUnclaimedSites] = useState<SiteOwnership[]>([]);

  const refreshSites = async () => {

    const getSitesOwnedByUser = async () => {

      if (userId === undefined) {
        return;
      }

      const sites = await ServiceApiSites.getSitesOwnedByUser(userId);
      setSitesOwnedByUser(sites);
    }

    const getUnclaimedSites = async () => {
      const deviceId = ServiceFeatureAnalytics.getDeviceIdCreateIfDoesNotExist();
      const sites = await ServiceApiSites.getUnclaimedSites(deviceId);
      setUnclaimedSites(sites);
    }

    getSitesOwnedByUser();
    getUnclaimedSites();
  }

  useEffect(() => {

    // Get all the websites that belong to this user
    refreshSites();
  }, []);

  const handleClaimSite = async (siteId: string) => {

    if (sessionId === undefined) {
      return;
    }

    const deviceId = ServiceFeatureAnalytics.getDeviceIdCreateIfDoesNotExist();

    await ServiceApiSites.takeSiteOwnership(sessionId, siteId, deviceId);

    refreshSites();
  }

  return (
    <div className="user-sites-component">
      <StyleManagerPixelVaidosoComponent />
      <TabTitleComponent title={t('pixelVaidoso')} />

      <Container>
        <PageTitle title="Sites" />


        <h4>{t('newSite')}</h4>
        <p>{t('createUnlimitedSites')}</p>
        <Link to={'/1-click'}>
          <Button>{t('createNew')}</Button>
        </Link>

        <VerticalWhiteSpaceComponent amount={1} />

        {sitesOwnedByUser.length > 0 &&
          <>
            <h3>{t('yourSites')}</h3>
            <div className="sites-list">
              {
                sitesOwnedByUser.map(site => {
                  return <SiteInformationComponent
                    key={site.id}
                    site={site}
                    isClaimed={true}
                    handleClaimSite={handleClaimSite}
                  />
                })
              }
            </div>
          </>
        }

        {unclaimedSites.length > 0 &&
          <>
            <h3>{t('sitesWithoutUserAccount')}</h3>
            <p>{t('sitesWithoutAccountCanOnlyBeEdited')}</p>
            <div className="sites-list">
              {
                unclaimedSites.map(site => {
                  return <SiteInformationComponent
                    key={site.id}
                    site={site}
                    isClaimed={false}
                    handleClaimSite={handleClaimSite}
                  />
                })
              }
            </div>
          </>
        }

      </Container>
    </div>
  );
}
