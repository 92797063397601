import { Button, Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { JumbotronComponent } from "../../common-frontend/components/jumbotron/jumbotron";
import { VerticalListComponent } from "../../common-frontend/components/verticalList/verticalList";
import { VerticalWhiteSpaceComponent } from "../../common-frontend/components/verticalWhiteSpace/verticalWhiteSpace";
import { useScroll } from "../../common-frontend/hooks/scroll/useScroll";
import { ContactUsComponent } from "../../components/home/contactUs/contactUs";
import { CreateSiteActionComponent } from "../../components/home/createSiteAction/createSiteAction";
import { ReasonsComponent } from "../../components/home/reasons/reasons";
import { WhyUsComponent } from "../../components/home/whyUs/whyUs";
import { StyleManagerPixelVaidosoComponent } from "../../components/sites/rendering/styleManager/styleManagerPixelVaidoso";
import { TabTitleComponent } from "../../common-frontend/components/tabTitle/tabTitleComponent";

import './pageHome.css';

export function PageHome(): JSX.Element {

  const { t } = useTranslation();

  const [scrollContactUs, contactUsParentRef] = useScroll<HTMLDivElement>();

  const renderActionButtons = (): JSX.Element => {
    return <div className="action-buttons-container">
      <Button
        size="lg"
        onClick={() => { scrollContactUs() }}>
        {t('contactUs')}
      </Button>
      <Button
        size="lg"
        variant="outline-primary"
        disabled >
        {t('demos')}
      </Button>
    </div>
  }

  return (
    <div className="home-page">
      <StyleManagerPixelVaidosoComponent />
      <TabTitleComponent title={t('pixelVaidoso')} />

      <Container>
        <div className="main-container">

          <JumbotronComponent text={t('onlinePresenceFocus')} />

          <div className="message-1">
            {t('noTechnicalKnowledgeRequired')}
            <br />
            {t('hereToHelpAndGuide')}
          </div>

          <VerticalListComponent>
            <div style={{ width: '100%', textAlign: 'center' }}>
              <h4>
                {t('wantSomethingSimple')}
              </h4>

            </div>
            <CreateSiteActionComponent />
          </VerticalListComponent>

          <div className="complex-sites">

            <VerticalListComponent>
              <div style={{ width: '100%', textAlign: 'center' }}>
                <h4>
                  {t('wantSomethingMorePersonalized')}
                </h4>
                <h4>
                  {t('contactUsForQuote')}
                </h4>
              </div>

              {renderActionButtons()}
            </VerticalListComponent>
          </div>

          <ReasonsComponent />
          <WhyUsComponent />
          <div ref={contactUsParentRef}>
            <ContactUsComponent />
          </div>
        </div>

        <VerticalWhiteSpaceComponent amount={2} />
      </Container>
    </div>
  );
}